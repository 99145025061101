<template>
  <layout width="420">
    <template slot="header"> Add existing object </template>
    <template>
      <validation-observer ref="form">
        <v-form>
          <validation-provider
            v-slot="{ errors }"
            name="Scheme type"
            rules="required"
          >
            <v-select
              v-model="state.schemaType"
              :items="schemaTypes"
              :error-messages="errors"
              item-text="name"
              item-value="id"
              label="Scheme type"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Scheme name"
            rules="required"
          >
            <v-autocomplete
              v-model="state.schemaId"
              :items="schemas"
              :loading="schemasLoading"
              :error-messages="errors"
              item-text="name"
              item-value="id"
              label="Scheme name"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Object name"
            rules="required"
          >
            <v-autocomplete
              v-model="state.existingObjectId"
              :items="existingObjects"
              :loading="existingObjectsLoading"
              :error-messages="errors"
              item-text="name"
              item-value="id"
              label="Object"
            />
          </validation-provider>
        </v-form>
      </validation-observer>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="exec"
      >
        Add
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, computed, watch } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { linkedObjectsService } from '@/modules/linked-objects/api';
import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { abcSort } from '@/compositions/sortBy';
import { objectService, schemasService } from '@/modules/common/api';
import { useSnackBar } from '@/compositions/snackBar';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: 'LinkedObjectEdit',
  components: {
    Layout,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    objectId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const schemaTypesDefinition = [
      {
        id: 'device',
        name: 'Device'
      },
      {
        id: 'dataset',
        name: 'Dataset'
      }
    ];

    const state = ref({
      schemaType: schemaTypesDefinition[0].id,
      schemaId: '',
      existingObjectId: '',
      enabled: true
    });

    const form = ref(null);

    const schemaTypes = ref(schemaTypesDefinition);

    const { show } = useSnackBar();

    const submit = async () => {
      await objectService
        .link(props.objectId, state.value.existingObjectId)
        .then(() => emit('submit'))
        .catch(error => show(error));
      emit('close');
    };

    const {
      exec: updateSchemas,
      loading: schemasLoading,
      result: schemas
    } = usePromise(
      () =>
        schemasService.fetch(state.value.schemaType.toUpperCase(), true, {
          not: {
            mTags: {
              contains: 'monitor'
            }
          },
          parentSchemaExists: false,
          objectsCount: { greaterThan: 0 }
        }),
      true
    );

    const {
      exec: updateExistingObjects,
      loading: existingObjectsLoading,
      result: existingObjects
    } = usePromise(
      () =>
        linkedObjectsService.fetchAllShort(
          state.value.schemaType,
          state.value.schemaId
        ),
      true
    );

    watch(
      () => state.value.schemaType,
      () => {
        updateSchemas();
        state.value.schemaId = '';
        state.value.existingObjectId = '';
      },
      {
        immediate: true
      }
    );

    watch(
      () => state.value.schemaId,
      () => {
        updateExistingObjects();
        state.value.existingObjectId = '';
      }
    );

    const { loading, exec } = usePromise(submit, true);

    return {
      state,
      exec,
      loading,
      existingObjectsLoading,
      existingObjects: computed(() =>
        (existingObjects.value || []).sort((a, b) => abcSort(a.name, b.name))
      ),
      form,
      schemaTypes,
      schemas,
      schemasLoading
    };
  }
};
</script>
